const generalMessages = {
    add: 'Add',
    all: 'All',
    are_you_sure: 'Are you sure?',
    cancel: 'Cancel',
    choose: 'Choose',
    delete: 'Delete',
    deleting: 'Deleting...',
    delete_succeed: 'Deleted',
    delete_failed: 'Delete failed',
    description: 'Description',
    duplicate: 'Duplicate',
    duplicate_succeed: 'Duplicated succesfully',
    edit: 'Edit',
    previous: 'Previous',
    save: 'Save',
    saving_changes: 'Saving changes',
    search: 'Search',
    save_failed: 'Save failed',
    save_succeed: 'Saved',
    sent: 'Sent',
    today: 'Today',
    name: 'Name',
    next: 'Next',
    type: 'Type',
    week: 'Week',
    error_occurred: 'Error occurred',
    organization: 'Organization',
    back: 'Back',
    car: 'Car',
    driver: 'Driver',
    passenger: 'Passenger',
    customer: 'Customer',
    orderer: 'Orderer',
    attachment: 'Attachment',
    attachments: 'Attachments',
    vehicle_class: 'Vehicle class',
    drive_type: 'Drive type',
    form_input_required: '* required',
    complete_button: 'Complete',
    no_search_results: 'No search results',
    pers: 'pers.',
    remove: 'Remove',
    error_boundary_msg: 'Something went wrong, refresh the page and try again.',
    update: 'Update',
    yes: 'Yes',
    no: 'No',
    google_estimate: 'ride Google™ estimate',
    estimate: 'Estimate',
    service_provider: 'Service provider',
    usage_policy: 'Usage policy',
}

const roleMessages = {
    role_customer: 'User',
    role_driver: 'Driver',
    role_organizer: 'Organizer',
    role_admin: 'Admin',
    role_organization_admin: 'Organization admin',
    role_disabled: 'Disabled',
}

const sidebarMessages = {
    sidebar_new_order: 'New Order',
    sidebar_orders: 'Orders',
    sidebar_rides: 'My rides',
    sidebar_organizing: 'Ride Management',
    sidebar_partners: 'Partners',
    sidebar_vehicles: 'Cars',
    sidebar_vehicle_categories: 'Vehicle Types',
    sidebar_vehicle_schedules: 'Vehicle schedules',
    sidebar_users: 'Company & Users',
    sidebar_companies: 'Companies',
    sidebar_api_documentation: 'API Documentation',
    sidebar_login: 'Login',
    sidebar_logout: 'Logout',
    sidebar_my_schedule: 'My Schedule',
    sidebar_pricing: 'Pricing',
}

const profileMessages = {
    profile_profile: 'My Profile',
    profile_card_details: 'Cards',
    profile_change_password: 'Change password',
    profile_fi: 'Suomeksi',
    profile_en: 'In English',
    profile_change_password_instructions: 'Change password below.',
    profile_old_password: 'Current password',
    profile_new_password: 'New password',
    profile_new_password_check: 'Confirm new password',
    profile_username: 'Username / Email address',
    profile_password_changed: 'Password changed',
    profile_password_mismatch: 'Check the entered passwords',
    profile_fullname: 'Name (Firstname Lastname)',
    profile_phone: 'Phone (Including country code +358...)',
    profile_save: 'Save',
}

const loginMessages = {
    login_title: 'Login',
    login_username: 'Email',
    login_password: 'Password',
    login_password_mismatch:
        "Check the password fields. Passwords don't match.",
    login_remember_me: 'Remember me',
    login_login: 'Login',
    login_logout: 'Logout',
    login_error: 'Login error',
    login_failed: 'Login failed',
    login_forgot_email_sent: 'Email sent',
    login_forgot_check_inbox: 'Check your email and follow the instructions',
    login_forgot_instructions:
        'Enter your email address to receive password reset instructions.',
    login_forgot_password: 'Forgot your password?',
    login_forgot_password_title: 'Forgot Password',
    login_forgot_send_email: 'Send email',
    login_refresh_session: 'Refresh session',
    login_reset_password_changed: 'Password changed',
    login_reset_expired: 'Link expired',
    login_reset_title: 'Set new password',
    login_reset_instructions: 'Set new password below.',
    login_reset_new_password: 'New password',
    login_reset_new_password_confirmation: 'Confirm new password',
    login_reset_set_password: 'Set password',
    login_reset_password_login:
        'Your password is now set and you can login to the service',
    login_session_expired: 'Your session expired',
    login_success: 'Login successful',
    login_invalid_email: 'Check your email address',
    login_logging_out: 'Logging out...',
    login_logged_out: 'You successfully logged out',
}

const organizationMessages = {
    org_title: 'Company management',
    org_add_new: 'Add new',
    org_company: 'Company',
    org_company_name: 'Company name',
    org_company_type: 'Company type',
    org_delete_failed: "Company couldn't be deleted",
    org_delete_ok: 'Company deleted',
    org_type_description:
        'Choose organization type. "Customer" is organization that has users who book trips. "Operator" is organization that provides the transportation service (drivers etc.). User with Admin role can create operator service provider companies.',
    org_type_customer: 'Customer',
    org_type_operator: 'Operator',
    org_save_failed: 'Company save failed',
    org_customer_order_owner: "Customer's primary service provider",
}

const registrationMessages = {
    reg_expired_error: 'Registration expired',
    reg_password_complexity:
        'Password needs to be at least 8 characters long and it should contain both uppercase and lowercase characters - also numbers and special characters are recommended to use',
    reg_phone_error: 'Check phone number format (+358123123123)',
    reg_password_missmatch: 'Your passwords do not match.',
    reg_registration_success: 'Registration succeeded.',
    reg_registration_failed: 'Registration failed',
    reg_invite_expired: 'Register invitation expired',
    reg_welcome: 'Welcome!',
    reg_welcome_text:
        'Hi! You have been invited to register to our service. Please fill in your details and a profile will be created for you and you can start using our service.',
    reg_full_name_label: 'Name (Firstname Surname)',
    reg_email_label: 'Email address',
    reg_phone_number_label: 'Phonenumber (e.g +358...)',
    reg_password_label: 'Password',
    reg_password_re_enter_label: 'Re-enter password',
    reg_password_text:
        'Choose a password that will be used to sign in to the service. A good password should be at least 8 characters long and include uppercase and lowercase characters and numbers. Password should also include special symbols.',
    reg_tos_part1: 'I accept the',
    reg_tos_part2: 'terms of service',
    reg_dialog_registration_success: 'Registration succeeded!',
    reg_dialog_content:
        'Your account is not activated. You may now log in to our service.',
    reg_dialog_ok_button: 'OK',
}

const tableMessages = {
    table_row_select: 'rows',
    table_first_page: 'First page',
    table_last_page: 'Last page',
    table_next_page: 'Next page',
    table_previous_page: 'Previous page',
    table_actions: 'Actions',
    table_empty: 'No data',
    table_filter: 'Filter',
    table_delete_verify: 'Are you sure you want to delete the item?',
}

const vehicleMessages = {
    vehicle_management_title: 'Vehicle management',
    vehicle_category: 'Category',
    vehicles: 'Vehicles',
    vehicle: 'Vehicle',
    vehicle_name: 'Vehicle name',
    vehicle_license_plate_example: 'License plate (ie. ABC-123)',
    vehicle_license_plate: 'License plate',
    vehicle_identifer_example: 'Own identifier (ie. A1234)',
    vehicle_description_example: 'Description details (ie. color, year, etc.)',
    vehicle_categories: 'Vehicle types',
    vehicle_category_max_passenger_count: 'Max passenger count',
    vehicle_category_max_baggage_count: 'Max baggage count',
    vehicle_category_time_limit_create: 'Time limit for order creation',
    vehicle_category_time_limit_modify: 'Time limit for modifying order',
    vehicle_category_time_limit_cancel: 'Time limit for cancelling order',
    vehicle_category_hours: 'Hours',
    vehicle_owner: 'Owner',
}

const orders = {
    order_reserve_ride: 'Orders',
    order_save_progress: 'Saving changes...',
    order_save_success: 'Order saved successfully',
    order_save_error: 'Order save failed',
    order_create_progress: 'Creating order...',
    order_create_success: 'Order created successfully',
    order_create_error: 'Order creation failed',
    order_file_upload_error: 'Attachment upload failed',
    order_get_rides_error: 'Fetching rides failed',
    order_get_vehicleCategories_error: 'Fetching vehicle categories failed',
    order_get_organizationUsers_error: 'Fetching organization users failed',
    order_get_organizations_error: 'Fetching organizations failed',
    order_fileupload_failed: 'Failed to delete file',
    order_other_passenger: 'Other...',
    order_show_trip_google_maps: 'Show trip in Google Maps',
    order_order_number: 'Order number',
    order_customer_number: 'Customer number',
    order_orderer: 'Orderer',
    order_other_passenger_name_label: 'Passenger / contact persons name',
    order_phonenumber_label: 'Phonenumber',
    order_email_label: 'Email',
    order_company_label: 'Company',
    order_pickup_date: 'Pickup date',
    order_pickup_date_finnish_timezone: 'Pickup date in Finnish time',
    order_pickup_time: 'Pickup time',
    order_planned_pickup_time_error_part1:
        'To ensure organizing the ride, the order cannot be created',
    order_planned_pickup_time_error_part2:
        'hours before the pickup time for the chosen',
    order_planned_pickup_time_error_part3: 'drive type',
    order_planned_pickup_time_error_editable_part1: 'The order details cannot be changed',
    order_planned_pickup_time_error_editable_part2: 'hours before the pickup time for the chosen',
    order_planned_pickup_time_error_editable_part3: 'drive type',
    order_car_alt_text: 'car picture',
    order_car_type: 'Vehicle type',
    order_car_fits_text_part1: '-car fits',
    order_car_fits_text_part2: 'passengers and',
    order_car_fits_text_part3: 'baggage.',
    order_drive_type: 'Drive type',
    order_drive_type_route: 'Route driving',
    order_drive_type_hourly: 'Hourly driving',
    order_drive_type_hourly_disclaimer: 'The minimum charge for an hourly drive is 3 hours. Hourly driving is only available in the Helsinki metropolitan area.',
    order_start: 'Start',
    order_destination: 'Destination',
    order_change_start_and_destination:
        'Change start and destination addresses',
    order_passenger_count: 'Passenger count',
    order_chosen_vehicle_max_persons:
        'The chosen vehicle max passenger count is',
    order_add_one_passenger: '* add at least one passenger',
    order_additional_information: 'Additional information',
    order_show_additional_information_passenger_sms:
        'Show additional information also in passenger SMS message.',
    order_add_attachment_button: 'Add attachment',
    order_cancel_order_button: 'Cancel order',
    order_send_order_button: 'Send order',
    order_save_order_button: 'Save changes',
    order_attachment_are_you_sure: 'Are you sure?',
    order_attachment_cannot_be_retrieved:
        'If you delete the file, it will be immediately deleted and it cannot be retrived later.',
    order_date_cancelled: 'Cancelled',
    orders_download_all_week_orders_as_csv: 'Export orders as CSV file',
    order_invoiced: 'Invoiced',
    order_cards_can_be_added_from: 'You can add new card in',
    orders_user_profile_card_link_title: 'user profile view',
    orders_card_own_orders: 'You can currently only pay your own rides with card',
    orders_card_expired: 'Card is expired at time of this ride',
    order_terminal_pickup: 'Terminal pickup (add train, ship or flight number to additional information field)',
}

const partners = {
    partners_empty_list:
        'No groups yet. Click button below to create first group.',
    partners_load_failed: 'Failed to load partners',
    partners_title: 'Partner groups',
    partners_create_new_list: 'Create new group',
    partners_list_title: 'Partner Group',
    partners_list_name: 'Group name',
    partners_delete_title: 'Are you sure?',
    partners_delete_text: 'Do you want to delete the chosen partner?',
}

const rides = {
    ride_rides_header: 'My rides',
    ride_cancel_success: 'Ride cancelled successfully',
    ride_cancel_error: 'Failed to cancel ride',
    ride_save_approved_ride_success: 'Ride details saved',
    ride_save_approved_ride_failed: 'Failed to save ride details',
    ride_confirm_ride: 'Confirm ride?',
    ride_sign_ride: 'Sign ride confirmed',
    ride_confirm: 'Confirm',
    ride_driver_started_text:
        "When moving to pickup location, passenger will be sent a SMS containing a link containing a map och pickup location, pickup time and driver's contact information",
    ride_driver_move_to_location: 'Move to location',
    ride_driver_starting_ride: 'Starting ride',
    ride_driver_start_ride: 'Start ride',
    ride_driver_note: "Driver's note",
    ride_not_completed_rides: 'Not completed orders',
    ride_completed_rides: 'Completed orders',
    ride_no_rides_today: 'No rides for this day',
    ride_end_ride_button: 'End ride',
    ride_completed_ride_button: 'Edit ride',
    ride_move_to_location_button: 'Move to location',
    ride_start_ride_button: 'Start ride',
    ride_actual_ride_length: 'Ride length',
    ride_actual_ride_duration: 'Ride duration',
    ride_length: 'Ride length',
    ride_duration: 'Ride duration',
    ride_waiting_time: 'Waiting time',
    ride_waiting_time_mm: 'Waiting time (min.)',
    ride_other_notes: 'Other notes',
    ride_expected_duration: 'Expected duration',
    ride_expected_arrival_time: 'Expected arrival time',
    ride_estimated_trip_length_km: 'Expected trip length',
    ride_ride_number: 'Ride no',
    ride_departure: 'Departure',
    ride_add_new_destination: 'Add new destination',
    ride_start_time_from_destination: 'Departure time from destination (if available)',
    ride_start_time: 'Departure time',
    ride_destination: 'Destination',
    ride_estimated_travel_time: 'Estimated travel duration',
    ride_estimated_arrival_time: 'Estimated arrival time',
    ride_additional_information: 'Additional information',
    ride_show_info_for_passenger: 'is visible to passenger',
    ride_hidden_info_for_passenger: 'is not visible to passenger',
    ride_ending_time_hh_mm: 'Ride completed (hh:mm)',
    ride_start_time_hh_mm: 'Ride started (hh:mm)',
    ride_drive_type_one_way: 'One-way ride',
    ride_drive_type_hour: 'Hour based ride',
    ride_car_and_driver: 'Car and driver',
    ride_confirm_order_to_define_driver_and_car:
        'Confirm the order first to be able to add a driver and car',
    ride_remember_to_save: 'Remember to save!',
    ride_handled_orders: 'Handled orders (+-5h)',
    ride_this_ride: '(this ride)',
    ride_save_driver_button: 'Save driver',
    ride_driver_dialog_choose_driving_shift: 'Choose driving shift',
    ride_driver_dialog_no_shifts: 'No shifts for this time',
    ride_free_up_success: 'Ride has been set free',
    ride_free_up_failed: 'Error occured when trying to free up the ride',
    ride_free_up_confirmation:
        'If you free up the ride it returns to the organizers view. This action is irreversible.',
    ride_free_up: 'Free up',
    ride_invoice_load_error: 'Error loading receipt info',
    ride_invoice_not_found: 'No receipt found',
    ride_endride_error_minApprovedTripLength: 'The trip length cannot be a negative number',
    ride_route_has: 'Route has',
    ride_waypoints: 'waypoints',
    ride_waypoint: 'waypoint',
    ride_route_stop: 'Waypoint',
    ride_drag_sort: 'Sort by dragging',
    ride_terminal_pickup: 'Terminal pickup',
    ride_additional_services: 'Additional services',
}

const schedules = {
    schedules_add: 'Add new schedule',
    schedules_date: 'Date',
    schedules_delete_confirmation_title: 'Are you sure?',
    schedules_delete_description:
        'Are you sure you want to delete this schedule?',
    schedules_total_abbrv: 'Total',
    schedules_load_error: 'Error loading schedules',
    schedules_vehicles_load_error: 'Error loading vehicles',
    schedules_schedule: 'Schedule',
    schedules_select_category: 'Select category',
    schedules_title: 'Schedules management',
    schedules_vehicle: 'Vehicle',
    schedules_driver: 'Driver',
    schedules_starttime: 'Start time',
    schedules_duration: 'Duration',
    schedules_hours: 'Hours',
    schedules_minutes: 'Minutes',
    schedules_start: 'Start',
    schedules_end: 'End',
    schedules_my_schedule_title: 'My schedule',
    schedules_prev_shift: 'Previous shift for the car.',
    schedules_next_shift: 'Next shift for the car',
}

const organizer = {
    organizer_orders: 'Ride management',
    organizer_show: 'Show',
    organizer_order_confirmation_success: 'Order confirmed',
    organizer_order_confirmation_failed: 'Failed to confirm order',
    organizer_driver_assigned_success: 'Driver assigned',
    organizer_driver_assigned_failed: 'Failed to assign driver',
    organizer_filter_unconfirmeds: 'Unconfirmed',
    organizer_filter_unhandleds: 'Unhandled',
    organizer_filter_confirmeds: 'Handled',
    organizer_filter_driver_acknowledgeds: 'Driver acknowledged',
    organizer_filter_cancelleds: 'Cancelled',
    organizer_filter_in_progress: 'In progress',
    organizer_filter_driver_started: 'Driver started',
    organizer_filter_ride_ended: 'Completed rides',
    organizer_no_orders_today: 'No orders for this day',
    organizer_dialog_modalConfirmButtonText: 'Confirm',
    organizer_dialog_headerText: 'Confirm order?',
    organizer_dialog_typo1:
        'An email confirmation will be sent to the customer if they opted-in for a confirmation when sending the order. The email includes the order information and a calendar booking.',
    organizer_dialog_typo2: 'Confirmation can not be cancelled',
    organizer_show_to_partners: 'Show to partner groups',
    organizer_partners: 'Partner groups',
    organizer_order_owner: 'Service provider',
    organizer_choose_partners: 'Choose partner groups',
    organizer_show_schedules_for_vehicle_class:
        'Show schedules for vehicle class',
    organizer_cancel_ride_success: 'Ride canceled',
    organizer_cancel_ride: 'Cancel ride',
    organizer_cancel_ride_confirmation: 'Cancel the ride?',
    organizer_edit_ride: 'Edit ride',
    organizer_edit_ride_confirmation: 'Edit ride',
}

const pricing = {
    pricing_category_not_set: 'Category not set',
    pricing_not_found: 'Pricing details not found for given parameters',
    pricing_title: 'Pricing',
    pricing_holidays_title: 'Holidays',
    pricing_holiday_name_fin: 'Name (FI)',
    pricing_holiday_name_eng: 'Name (EN)',
    pricing_holiday_start_date: 'Date',
    pricing_components: 'Pricing components',
    pricing_daytype: 'Week/holiday',
    pricing_hourdriveprice: 'Hourly drive (€/h)',
    pricing_hourlydrivebaseprice: 'Hourly drive base fee',
    pricing_minimalfee: 'Minimum',
    pricing_baseprice: 'Base fee',
    pricing_waitingprice: 'Wait (€/h)',
    pricing_starthour: 'From',
    pricing_endhour: 'To',
    pricing_priceperkilometer: 'Kilometer (€/km)',
    pricing_item: 'Item',
    pricing_quantity: 'Quantity',
    pricing_unit_price: 'Unit price',
    pricing_terminalpickupprice: 'Terminal pickup fee',
    pricing_total: 'Total',
    price_total_abbrv: 'Tot.',
    pricing_total_vat: 'Total VAT',
    pricing_waiting_time: 'Waiting time',
    pricing_ride_length: 'Ride length',
    pricing_minimal_fee: 'Minimum fee',
    pricing_test_invoice: 'Test invoice',
    pricing_test_ride: 'Test ride details',
    pricing_start_date: 'Start date',
    pricing_end_date: 'End date',
    pricing_vat_percent: 'VAT %',
    pricing_lists: 'Pricing lists',
    pricing_select_list: 'Select pricing list',
    pricing_tab_pricing_lists: 'Pricing lists',
    pricing_tab_price_components: 'Price components',
    pricing_tab_holidays: 'Holidays',
    pricing_tab_tester: 'Test tool',
    pricing_start_time: 'Start time',
    pricing_weekday_ride: 'Weekday',
    pricing_weekend_or_holiday_ride: 'Weekend or holiday',
    pricing_service_provider: 'Service provider',
    pricing_customer: 'Customer',
    priceitem_hourly_drive: 'Hourly drive',
    priceitem_base_fee: 'Basic fee',
    priceitem_hourlydrivebase_fee: 'Basic hourly drive fee',
    priceitem_km_fee: 'Kilometers',
    priceitem_waiting_fee: 'Waiting time (hours)',
    priceitem_terminal_pickup_fee: 'Terminal pickup fee',
    price_estimate: '(estimate)',
    price_minimum_price: 'Minimum price',
    price_estimate_price: 'Estimated price',
    price_no_estimate: 'Could not create price estimate',
    price_hourly_min_hours: '3h (minimum)',
}

const users = {
    users_role: 'Role',
    users_role_driver: 'Driver',
    users_role_organizer: 'Organizer',
    users_role_admin: 'Service admin',
    users_role_customer: 'User',
    users_role_customeradmin: 'Organization admin',
    users_load_failed: 'Error loading users',
    users_invite_new_user: 'Invite new user',
    users_invitation_email: 'Email address',
    users_send_invite: 'Send invitation',
    users_users: 'Users',
    users_management_title: 'Company & user management',
    users_role_changed_success: 'Role changed successfully',
    users_role_changed_error: 'Role change failed',
    users_user_disabled_success: 'User disabled successfully',
    users_invoicing_info: 'Billing information',
    users_billing_email: 'Billing email address',
    users_billing_street_address: 'Company postal address',
    users_billing_company_identifier:
        'Finnish VAT identifier (in format 1234567-8)',
}

const datepicker = {
    datepicker_error_minDateMessage: 'Date cannot be in the past',
}

const serverErrors = {
    serverError_common_server_error: 'Server error',
    serverError_too_much_data_to_load:
        'Too much data to load. Showing only the part of data.',
}

const passengerInfoMessages = {
    info_pickup_time: 'Pickup time',
    info_pickup_address: 'Pickup address',
    info_pickup_info: 'Additional information',
    info_driver: 'Driver',
    info_licenseplate: 'Licenseplate',
    info_vehicle: 'Car',
    info_infophone: 'Service contact',
    info_not_available: 'Information not available',
    info_car_is_on_its_way: 'Your car is on its way',
    info_ride_information: 'Ride information',
}

const weekDayMessages = {
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
}

const cancelModal = {
    cancel_modal_cancel_order: 'Cancel order',
    cancel_modal_cancel_order_overdue_part1:
        'Cancelling this order incurs costs, because the start time of the ride is under',
    cancel_modal_cancel_order_overdue_part2: 'hours.',
    cancel_modal_cancel_order_not_overdue_part1:
        'Cancelling this order is free of charge, because the start time of the ride is over ',
    cancel_modal_cancel_order_button: 'Cancel order',
}

const confirmOrderModal = {
    confirm_modal_confirm_order: 'Confirm order',
    confirm_modal_cancel_text_part1:
        'You may cancel the order without any charges',
    confirm_modal_cancel_text_part2:
        'hours before the start of the ride and make changes to the order',
    confirm_modal_cancel_text_part3: 'hours before start of the order.',
    confirm_modal_handle_orders_fast:
        'We strive to handle all orders as fast as possible.',
    confirm_modal_email_checbox_label:
        'When the order is confirmed, send me an email with the confirmation information and a calendar booking.',
    confirm_modal_order_button: 'Send order',
    confirm_accept_policy_on_send_partial: 'By sending the order you accept ',
    confirm_usage_policy_link_title: 'usage policy',
}

const status = {
    status_not_completed: 'Not confirmed',
    status_confirmed: 'Confirmed',
    status_not_handled: 'Not handled',
    status_handled: 'Handled',
    status_not_signed: 'Not signed',
    status_signed: 'Signed',
}

const cards = {
    cards_get_cards_error: 'Fetching card details failed',
    cards_add_new_card: 'Add new card',
    cards_cards: 'Cards',
    cards_delete_card_error: 'Card remove failed. Contact support.',
    cards_delete_card_success: 'Card removed',
    cards_confirm_card_delete: 'Yes, I want to remove the card',
    cards_delete_card_modal_header_text: 'Remove card',
    cards_delete_card_modal_typo1: 'You surely want to remove the card? If there are open orders for the card then payment type will be changed to "invoice" for those orders.',
    cards_payment_method: 'Payment method',
    cards_credit_card: 'Credit card',
    cards_invoice: 'Invoice',
    cards_choose_credit_card: 'Choose credit card',
    cards_rename_title: 'Rename card',
    cards_name: 'Name',
    cards_maskedPan: 'Card number',
    cards_edit_card_name: 'Edit card name',
    cards_add_new_card_error: 'Adding new card failed',
    cards_other_user_card: '(Customer credit card)',
    cards_new_card_added: 'New card added successfully',
    cards_charge: 'Charge card',
    cards_charge_in_progress: 'Charging...',
    cards_charge_complete: 'Charge complete',
    cards_charge_failed: 'Charging failed',
}

const backendErrorMsgs = {
    invalid_passenger_count: 'Check passenger count',
}

export default {
    ...roleMessages,
    ...generalMessages,
    ...sidebarMessages,
    ...loginMessages,
    ...organizationMessages,
    ...registrationMessages,
    ...tableMessages,
    ...vehicleMessages,
    ...orders,
    ...partners,
    ...organizer,
    ...pricing,
    ...rides,
    ...schedules,
    ...datepicker,
    ...serverErrors,
    ...users,
    ...profileMessages,
    ...passengerInfoMessages,
    ...weekDayMessages,
    ...cancelModal,
    ...confirmOrderModal,
    ...status,
    ...cards,
    ...backendErrorMsgs,
}
