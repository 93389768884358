const generalMessages = {
    add: 'Lisää',
    all: 'Kaikki',
    are_you_sure: 'Oletko varma?',
    cancel: 'Peruuta',
    choose: 'Valitse',
    delete: 'Poista',
    deleting: 'Poistetaan...',
    delete_succeed: 'Poistettu',
    delete_failed: 'Poisto epäonnistui',
    description: 'Kuvaus',
    duplicate: 'Kopioi',
    duplicate_succeed: 'Kopiointi onnistui',
    edit: 'Muokkaa',
    previous: 'Edellinen',
    save: 'Tallenna',
    saving_changes: 'Tallennetaan...',
    search: 'Hae',
    save_failed: 'Tallennus epäonnistui',
    save_succeed: 'Tallennus onnistui',
    sent: 'Lähetetty',
    today: 'Tänään',
    name: 'Nimi',
    next: 'Seuraava',
    type: 'Tyyppi',
    week: 'Viikko',
    error_occurred: 'Tapahtui virhe',
    organization: 'Yritys',
    back: 'Takaisin',
    car: 'Auto',
    driver: 'Kuljettaja',
    passenger: 'Matkustaja',
    customer: 'Asiakas',
    orderer: 'Tilaaja',
    attachment: 'Liitetiedosto',
    attachments: 'Liitetiedostot',
    vehicle_class: 'Kuljetustyyppi',
    drive_type: 'Kuljetustarve',
    form_input_required: '* pakollinen kenttä',
    complete_button: 'Valmis',
    no_search_results: 'Ei hakutuloksia',
    pers: 'henk.',
    remove: 'Poista',
    error_boundary_msg:
        'Jokin meni pieleen, päivitä sivu ja kokeile uudestaan.',
    update: 'Päivitä',
    yes: 'Kyllä',
    no: 'Ei',
    google_estimate: 'matkan Google™ arvio',
    estimate: 'Arvio',
    service_provider: 'Palveluntarjoaja',
    usage_policy: 'Käyttöehdot',
}

const roleMessages = {
    role_customer: 'Peruskäyttäjä',
    role_driver: 'Kuljettaja',
    role_organizer: 'Ajojärjestelijä',
    role_admin: 'Pääkäyttäjä',
    role_organization_admin: 'Yrityksen pääkäyttäjä',
    role_disabled: 'Poistettu käytöstä',
}

const sidebarMessages = {
    sidebar_new_order: 'Uusi Tilaus',
    sidebar_orders: 'Tilaukset',
    sidebar_rides: 'Omat ajot',
    sidebar_organizing: 'Ajojärjestely',
    sidebar_partners: 'Kumppaniringit',
    sidebar_vehicles: 'Ajoneuvot',
    sidebar_vehicle_categories: 'Ajoneuvotyypit',
    sidebar_vehicle_schedules: 'Ajovuorot',
    sidebar_users: 'Yritys & käyttäjät',
    sidebar_companies: 'Yritykset',
    sidebar_api_documentation: 'API dokumentaatio',
    sidebar_login: 'Kirjaudu sisään',
    sidebar_logout: 'Poistu',
    sidebar_my_schedule: 'Omat ajovuorot',
    sidebar_pricing: 'Hinnoittelu',
}

const profileMessages = {
    profile_profile: 'Omat tiedot',
    profile_card_details: 'Maksukortit',
    profile_change_password: 'Vaihda salasana',
    profile_fi: 'Suomeksi',
    profile_en: 'In English',
    profile_change_password_instructions: 'Vaihda salasana alla.',
    profile_old_password: 'Nykyinen salasana',
    profile_new_password: 'Uusi salasana',
    profile_new_password_check: 'Vahvista uusi salasana',
    profile_username: 'Käyttäjätunnus / Sähköpostiosoite',
    profile_password_changed: 'Salasana vaihdettu',
    profile_password_mismatch: 'Tarkista syötetyt salasanat',
    profile_fullname: 'Nimi (Etunimi Sukunimi)',
    profile_phone: 'Puhelinnumero (Muodossa +358...)',
    profile_save: 'Tallenna tiedot',
}

const loginMessages = {
    login_title: 'Sisäänkirjautuminen',
    login_username: 'Sähköpostiosoite',
    login_password: 'Salasana',
    login_password_mismatch: 'Salasana ei täsmää. Tarkista salasana-kentät.',
    login_remember_me: 'Muista minut',
    login_login: 'Kirjaudu sisään',
    login_logout: 'Poistu',
    login_error: 'Virhe sisäänkirjautumisessa',
    login_failed: 'Sisäänkirjautuminen epäonnistui',
    login_forgot_email_sent: 'Sähköposti lähetetty',
    login_forgot_check_inbox: 'Tarkista sähköpostisi ja seuraa ohjeita',
    login_forgot_instructions:
        'Syötä sähköpostiosoitteesi, niin lähetämme sinulle ohjeet salasanan vaihtoa varten.',
    login_forgot_password: 'Unohtuiko salasana?',
    login_forgot_password_title: 'Unohtuiko salasana?',
    login_forgot_send_email: 'Lähetä sähköposti',
    login_refresh_session: 'Jatka sovellukseen',
    login_reset_expired: 'Linkki ei ole enää voimassa',
    login_reset_password_changed: 'Salasana vaihdettu',
    login_reset_title: 'Luo uusi salasana',
    login_reset_instructions: 'Luo uusi salasana alla.',
    login_reset_new_password: 'Uusi salasana',
    login_reset_new_password_confirmation: 'Vahvista uusi salasana',
    login_reset_set_password: 'Aseta salasana',
    login_reset_password_login:
        'Salasanasi on nyt vaihdettu ja voit kirjautua palveluun.',
    login_session_expired: 'Istuntosi on vanhentunut',
    login_success: 'Sisäänkirjautuminen onnistui',
    login_invalid_email: 'Tarkista sähköpostiosoitteesi',
    login_logging_out: 'Kirjaudutaan ulos...',
    login_logged_out: 'Olet kirjautunut ulos',
}

const organizationMessages = {
    org_title: 'Yritysten hallinta',
    org_add_new: 'Lisää uusi',
    org_company: 'Yritys',
    org_company_name: 'Yrityksen nimi',
    org_company_type: 'Yrityksen tyyppi',
    org_delete_failed: 'Yrityksen poisto epäonnistui',
    org_delete_ok: 'Yrityksen poisto onnistui',
    org_type_description:
        'Valitse yrityksen tyyppi. "Asiakas"-yrityksen käyttäjät tilaavat kuljetuksia. "Palveluntarjoaja"-yrityksen käyttäjät ovat kuljettajia, ajojärjestelijöitä yms. palveluntarjoamiseen keskittyviä käyttäjiä. Vain Admin-käyttäjät voivat perustaa uusia palveluntarjojia.',
    org_type_customer: 'Asiakas',
    org_type_operator: 'Palveluntarjoaja',
    org_save_failed: 'Yrityksen tallennus epäonnistui',
    org_customer_order_owner: 'Asiakkaan ensisijainen palveluntarjoaja',
}

const registrationMessages = {
    reg_expired_error: 'Rekisteröintikutsu ei ole enää voimassa',
    reg_password_complexity:
        'Salasanan pitää olla vähintään 8 merkkiä pitkä ja siinä pitää olla pieniä ja isoja kirjaimia tai vaihtoehtoisesti numeroita.',
    reg_phone_error: 'Tarkista puhelinnumeron formaatti (+358123123123)',
    reg_password_missmatch:
        'Syötetyt salasanat eivät täsmää keskenään. Tarkista salasanakentät.',
    reg_registration_success: 'Rekisteröinti onnistui.',
    reg_registration_failed: 'Rekisteröinti epäonnistui',
    reg_invite_expired: 'Rekisteröintikutsu ei ole enää voimassa.',
    reg_welcome: 'Tervetuloa!',
    reg_welcome_text:
        'Hei! Teidät on kutsuttu rekisteröitymään palveluumme. Ole hyvä ja lisää tietosi, niin luomme teille profiilin ja voitte aloittaa palvelun käytön.',
    reg_full_name_label: 'Nimi (Etunimi Sukunimi)',
    reg_email_label: 'Sähköpostiosoite',
    reg_phone_number_label: 'Puhelinnumero (Muodossa +358...)',
    reg_password_label: 'Salasana',
    reg_password_re_enter_label: 'Salasana uudelleen',
    reg_password_text:
        'Valitse salasana millä tulet kirjautumaan palveluun. Hyvän salasanan tulee olla vähintään 8 merkkiä ja sisältää isoja ja pieniä kirjaimia sekä numeroita. Salasanassa kannattaa olla myös erikoismerkkejä.',
    reg_tos_part1: 'Hyväksyn palvelun',
    reg_tos_part2: 'käyttöehdot',
    reg_dialog_registration_success: 'Rekisteröinti onnistui!',
    reg_dialog_content:
        'Tunnuksesi on nyt aktivoitu. Seuraavaksi voit kirjautua sisään palveluun.',
    reg_dialog_ok_button: 'OK',
}

const tableMessages = {
    table_row_select: 'riviä',
    table_first_page: 'Ensimmäinen sivu',
    table_last_page: 'Viimeinen sivu',
    table_next_page: 'Seuraava sivu',
    table_previous_page: 'Edellinen sivu',
    table_actions: 'Toiminnot',
    table_empty: 'Ei tietoja',
    table_filter: 'Filtteröi',
    table_delete_verify: 'Oletko varma, että haluat poistaa rivin?',
}

const vehicleMessages = {
    vehicle_management_title: 'Ajoneuvojen hallinta',
    vehicle_category: 'Tyyppi',
    vehicles: 'Ajoneuvot',
    vehicle: 'Ajoneuvo',
    vehicle_name: 'Ajoneuvon nimi',
    vehicle_license_plate_example: 'Rekisterinumero (esim. ABC-123)',
    vehicle_license_plate: 'Rekisterinumero',
    vehicle_identifer_example: 'Oma tunniste (esim. A1234)',
    vehicle_description_example:
        'Tarkemmat tiedot (esim. väri, vuosimalli yms.)',
    vehicle_categories: 'Ajoneuvotyypit',
    vehicle_category_max_passenger_count: 'Matkustajien maksimimäärä',
    vehicle_category_max_baggage_count: 'Matkalaukkujen maksimimäärä',
    vehicle_category_time_limit_create: 'Aikaraja tilauksen luontiin',
    vehicle_category_time_limit_modify: 'Aikaraja tilauksen muokkaukseen',
    vehicle_category_time_limit_cancel: 'Aikaraja tilauksen perumiseen',
    vehicle_category_hours: 'Tuntia',
    vehicle_owner: 'Omistaja',
}

const orders = {
    order_reserve_ride: 'Tilaukset',
    order_save_progress: 'Tallennetaan tilausta...',
    order_save_success: 'Tilauksen tallennus onnistui',
    order_save_error: 'Tilauksen tallennus epäonnistui',
    order_create_progress: 'Luodaan tilausta...',
    order_create_success: 'Tilauksen luonti onnistui',
    order_create_error: 'Tilauksen luonti epäonnistui',
    order_file_upload_error: 'Liitteen lataus epäonnistui',
    order_get_rides_error: 'Ajojen haku epäonnistui',
    order_get_organizations_error: 'Organisaatiotietojen haku epäonnistui',
    order_get_vehicleCategories_error: 'Ajoneuvoluokkien haku epäonnistui',
    order_get_organizationUsers_error:
        'Organisaation käyttäjien haku epäonnistui',
    order_fileupload_failed: 'Tiedoston poisto epäonnistui',
    order_other_passenger: 'Muu...',
    order_show_trip_google_maps: 'Näytä reitti Google Mapsissa',
    order_order_number: 'Tilausnumero',
    order_customer_number: 'Asiakasnumero',
    order_orderer: 'Tilaaja',
    order_other_passenger_name_label: 'Matkustajan / yht. henkilön nimi',
    order_phonenumber_label: 'Puhelinnumero',
    order_email_label: 'Sähköposti',
    order_company_label: 'Yritys',
    order_pickup_date: 'Noutopäivämäärä',
    order_pickup_date_finnish_timezone: 'Noutopäivämäärä Suomen ajassa',
    order_pickup_time: 'Noutoaika',
    order_planned_pickup_time_error_part1:
        'Ajojärjestelyn takaamiseksi tilausta ei voi luoda enää',
    order_planned_pickup_time_error_part2: 'tuntia ennen noutoaikaa valitulle',
    order_planned_pickup_time_error_part3: 'kuljetukselle',
    order_planned_pickup_time_error_editable_part1: 'Ajojärjestelyn takaamiseksi tilausta ei voi muokata',
    order_planned_pickup_time_error_editable_part2: 'tuntia ennen noutoaikaa valitulle',
    order_planned_pickup_time_error_editable_part3: 'kuljetukselle',
    order_car_alt_text: 'autokuva',
    order_car_type: 'Autotyyppi',
    order_car_fits_text_part1: '-autoon mahtuu',
    order_car_fits_text_part2: 'matkustajaa ja',
    order_car_fits_text_part3: 'matkalaukkua.',
    order_drive_type: 'Kuljetustarve',
    order_drive_type_route: 'Reittiajo',
    order_drive_type_hourly: 'Tuntiajo',
    order_drive_type_hourly_disclaimer: 'Tuntiajon vähimmäisveloitus on 3 tuntia. Tuntiajo saatavilla vain pääkaupunkiseudulla.',
    order_start: 'Lähtö',
    order_destination: 'Kohde',
    order_change_start_and_destination: 'Vaihda lähtö- ja kohdeosoite',
    order_passenger_count: 'Matkustajamäärä',
    order_chosen_vehicle_max_persons:
        'Valitun auton matkustajien enimmäismäärä on',
    order_add_one_passenger: '* lisää vähintään yksi matkustaja',
    order_additional_information: 'Lisätiedot',
    order_show_additional_information_passenger_sms:
        'Näytä lisätiedot myös matkustajan tekstiviestin tiedoissa.',
    order_add_attachment_button: 'Lisää liitetiedosto',
    order_cancel_order_button: 'Peru tilaus',
    order_send_order_button: 'Lähetä tilaus',
    order_save_order_button: 'Tallenna muutokset',
    order_attachment_are_you_sure: 'Oletko varma?',
    order_attachment_cannot_be_retrieved:
        'Jos poistat tiedoston, niin se poistetaan heti eikä sitä voi enää palauttaa.',
    order_date_cancelled: 'Peruttu',
    orders_download_all_week_orders_as_csv: 'Lataa tilaukset CSV tiedostona',
    order_invoiced: 'Laskutettu',
    order_cards_can_be_added_from: 'Voit lisätä uuden kortin',
    orders_user_profile_card_link_title: 'omasta profiilista',
    orders_card_own_orders: 'Voit maksaa vain oman matkasi luottokortilla',
    orders_card_expired: 'Kortin pitää olla voimassa ajon jälkeen',
    order_terminal_pickup: 'Terminaalinouto (lisää junan, laivan tai lennon numero lisätiedot kenttään)',
}

const partners = {
    partners_empty_list:
        'Ei kumppanilistoja. Paina nappia alla luodaksesi ensimmäisen kumppaniringin.',
    partners_load_failed: 'Kumppanien haku epäonnistui',
    partners_title: 'Rinkien hallinta',
    partners_create_new_list: 'Luo uusi kumppanirinki',
    partners_list_title: 'Kumppanirinki',
    partners_list_name: 'Nimi',
    partners_delete_title: 'Oletko varma?',
    partners_delete_text: 'Haluatko poistaa valitun ryhmän?',
}

const rides = {
    ride_rides_header: 'Omat ajot',
    ride_cancel_success: 'Ajo peruutettu onnistuneesti',
    ride_cancel_error: 'Ajon peruutus epäonnistui',
    ride_save_approved_ride_success: 'Ajon tiedot tallennettu',
    ride_save_approved_ride_failed: 'Ajon tietojen tallennus epäonnistui',
    ride_confirm_ride: 'Vahvista ajo?',
    ride_sign_ride: 'Kuittaa ajo vahvistetuksi',
    ride_confirm: 'Vahvista',
    ride_driver_started_text:
        'Kun lähdet kohteeseen, kuljetettavalle lähetetään tekstiviesti, jossa on linkin takana kartta, ajankohta ja kuljettajan yhteystiedot.',
    ride_driver_move_to_location: 'Lähde kohteeseen',
    ride_driver_starting_ride: 'Aloitetaan matka',
    ride_driver_start_ride: 'Aloita matka',
    ride_driver_note: 'Kuljettajan kommentti',
    ride_not_completed_rides: 'Toimittamattomat tilaukset',
    ride_completed_rides: 'Toimitetut tilaukset',
    ride_no_rides_today: 'Ei ajoja tälle päivälle',
    ride_end_ride_button: 'Päätä ajo',
    ride_completed_ride_button: 'Muokkaa ajoa',
    ride_move_to_location_button: 'Lähde kohteeseen',
    ride_start_ride_button: 'Aloita matka',
    ride_actual_ride_length: 'Toteutunut matka',
    ride_actual_ride_duration: 'Toteutunut kesto',
    ride_length: 'Matka (km.)',
    ride_duration: 'Kesto',
    ride_waiting_time: 'Odotusaika',
    ride_waiting_time_mm: 'Odotusaika (min.)',
    ride_other_notes: 'Muuta huomioitavaa',
    ride_expected_duration: 'Arvioitu kesto',
    ride_estimated_trip_length_km: 'Arvioitu matkan pituus',
    ride_expected_arrival_time: 'Arvioitu saapumisaika',
    ride_ride_number: 'Ajonro.',
    ride_departure: 'Lähtö',
    ride_add_new_destination: 'Lisää uusi kohde',
    ride_start_time_from_destination: 'Noutoaika kohteesta (jos odotus kohteessa)',
    ride_start_time: 'Noutoaika',
    ride_destination: 'Kohde',
    ride_estimated_travel_time: 'Arvioitu matka-aika',
    ride_estimated_arrival_time: 'Arvioitu saapumisaika',
    ride_additional_information: 'Lisätiedot',
    ride_show_info_for_passenger: 'näytetään myös matkustajalle',
    ride_hidden_info_for_passenger: 'ei näy matkustajalle',
    ride_ending_time_hh_mm: 'Päättymisaika (hh:mm)',
    ride_start_time_hh_mm: 'Alkamisaika (hh:mm)',
    ride_drive_type_one_way: 'Yksisuuntainen matka',
    ride_drive_type_hour: 'Tuntiajo',
    ride_car_and_driver: 'Auto ja kuljettaja',
    ride_confirm_order_to_define_driver_and_car:
        'Vahvista tilaus, niin voit määritellä kuljettajan ja auton',
    ride_remember_to_save: 'Muista tallentaa!',
    ride_handled_orders: 'Käsitellyt tilaukset (+-5h)',
    ride_this_ride: '(tämä ajo)',
    ride_save_driver_button: 'Tallenna kuljettaja',
    ride_driver_dialog_choose_driving_shift: 'Valitse ajovuoro',
    ride_driver_dialog_no_shifts: 'Ei ajovuoroja tälle ajalle',
    ride_free_up_success: 'Ajo vapautettu',
    ride_free_up_failed: 'Ajon vapautuksessa tapahtui virhe',
    ride_free_up_confirmation:
        'Jos vapautat ajon, se siirtyy ajojärjestelijän listalle. Et voi perua tätä toimintoa.',
    ride_free_up: 'Vapauta',
    ride_invoice_load_error: 'Virhe ladatessa kuitin tietoja',
    ride_invoice_not_found: 'Kuittia ei löytynyt',
    ride_endride_error_minLengthKm: 'Matkan pituus ei saa olla negatiivinen luku',
    ride_route_has: 'Reitillä on',
    ride_waypoints: 'kohdetta',
    ride_waypoint: 'kohde',
    ride_route_stop: 'Välikohde',
    ride_drag_sort: 'Järjestä uudelleen vetämällä',
    ride_terminal_pickup: 'Terminaalinouto',
    ride_additional_services: 'Lisäpalvelut',
}

const schedules = {
    schedules_add: 'Lisää ajovuoro',
    schedules_date: 'Päivämäärä',
    schedules_delete_confirmation_title: 'Oletko varma?',
    schedules_delete_description: 'Haluatko poistaa tämän ajovuoron?',
    schedules_total_abbrv: 'Yht.',
    schedules_load_error: 'Virhe ajovuoroja ladatessa',
    schedules_vehicles_load_error: 'Virhe ajoneuvoja ladatessa',
    schedules_schedule: 'Ajovuoro',
    schedules_select_category: 'Valitse ajoneuvotyyppi',
    schedules_title: 'Ajovuorojen hallinta',
    schedules_vehicle: 'Ajoneuvo',
    schedules_driver: 'Kuljettaja',
    schedules_starttime: 'Aloitusaika',
    schedules_duration: 'Kesto',
    schedules_hours: 'Tuntia',
    schedules_minutes: 'Minuuttia',
    schedules_start: 'Aloitus',
    schedules_end: 'Lopetus',
    schedules_my_schedule_title: 'Omat ajovuorot',
    schedules_prev_shift: 'Auton edellinen vuoro',
    schedules_next_shift: 'Auton seuraava vuoro',
}

const organizer = {
    organizer_orders: 'Ajojärjestely',
    organizer_show: 'Näytä',
    organizer_order_confirmation_success: 'Tilaus vahvistettu',
    organizer_order_confirmation_failed: 'Virhe tilausta vahvistaessa',
    organizer_driver_assigned_success: 'Kuljettaja määritelty',
    organizer_driver_assigned_failed: 'Virhe kuljettajaa määriteltäessä',
    organizer_filter_unconfirmeds: 'Vahvistamattomat',
    organizer_filter_unhandleds: 'Käsittelemättömät',
    organizer_filter_confirmeds: 'Käsitellyt',
    organizer_filter_driver_acknowledgeds: 'Kuljettajan kuittaamat',
    organizer_filter_cancelleds: 'Peruutetut',
    organizer_filter_in_progress: 'Ajossa',
    organizer_filter_driver_started: 'Kuljettaja matkalla',
    organizer_filter_ride_ended: 'Ajetut',
    organizer_no_orders_today: 'Ei tilauksia tälle päivälle',
    organizer_dialog_modalConfirmButtonText: 'Vahvista',
    organizer_dialog_headerText: 'Vahvista tilaus?',
    organizer_dialog_typo1:
        'Jos tilaaja on halunnut tilauksen vahvistuksesta sähköpostin, se lähetetään tässä yhteydessä. Sähköposti sisältää tilauksen tiedot ja kalenterivarauksen.',
    organizer_dialog_typo2: 'Vahvistusta ei voi perua.',
    organizer_show_to_partners: 'Näytetään palveluntarjoajille',
    organizer_partners: 'Palveluntarjoajat',
    organizer_order_owner: 'Palveluntarjoaja',
    organizer_choose_partners: 'Valitse kumppaniringit',
    organizer_show_schedules_for_vehicle_class:
        'Näytä ajovuorot kuljetustyypille',
    organizer_cancel_ride_success: 'Ajo peruutettu',
    organizer_cancel_ride: 'Peru ajo',
    organizer_cancel_ride_confirmation: 'Vahvista ajon peruutus',
    organizer_edit_ride: 'Muokkaa ajoa',
    organizer_edit_ride_confirmation: 'Ajon muokkaus',
}

const pricing = {
    pricing_category_not_set: 'Kuljetustyyppiä ei määritelty',
    pricing_not_found: 'Parametreja vastaavia hinnastokomponentteja ei löydetty',
    pricing_title: 'Hinnoittelu',
    pricing_holidays_title: 'Pyhäpäivät',
    pricing_holiday_name_fin: 'Nimi (FI)',
    pricing_holiday_name_eng: 'Nimi (EN)',
    pricing_holiday_start_date: 'Pvm',
    pricing_components: 'Hinnastokomponentit',
    pricing_daytype: 'Arki/pyhä',
    pricing_hourdriveprice: 'Tuntiajo (€/h)',
    pricing_hourlydrivebaseprice: 'Tuntiajon perusmaksu',
    pricing_minimalfee: 'Minimi',
    pricing_baseprice: 'Perusmaksu',
    pricing_waitingprice: 'Odotus (€/h)',
    pricing_starthour: 'Mistä',
    pricing_endhour: 'Mihin',
    pricing_priceperkilometer: 'Kilometri (km)',
    pricing_item: 'Selite',
    pricing_quantity: 'Määrä',
    pricing_unit_price: 'Yksikköhinta',
    pricing_terminalpickupprice: 'Terminaalinouto',
    pricing_total: 'Yhteensä',
    price_total_abbrv: 'Yht.',
    pricing_total_vat: 'ALV osuus',
    pricing_waiting_time: 'Odotusaika',
    pricing_ride_length: 'Matkan pituus',
    pricing_minimal_fee: 'Vähimmäisveloitus',
    pricing_test_invoice: 'Testilasku',
    pricing_test_ride: 'Testiajon tiedot',
    pricing_start_date: 'Alkupvm.',
    pricing_end_date: 'Loppupvm.',
    pricing_vat_percent: 'ALV %',
    pricing_lists: 'Hinnastot',
    pricing_select_list: 'Valitse hinnasto',
    pricing_tab_pricing_lists: 'Hinnastot',
    pricing_tab_price_components: 'Hinnastokomponentit',
    pricing_tab_holidays: 'Pyhäpäivät',
    pricing_tab_tester: 'Testityökalu',
    pricing_start_time: 'Lähtö klo',
    pricing_weekday_ride: 'Arkiajo',
    pricing_weekend_or_holiday_ride: 'Viikonloppu tai pyhäpäivä',
    pricing_service_provider: 'Palveluntarjoaja',
    pricing_customer: 'Asiakas',
    priceitem_hourly_drive: 'Tuntiajo',
    priceitem_base_fee: 'Perusmaksu',
    priceitem_hourlydrivebase_fee: 'Tuntiajon perusmaksu',
    priceitem_km_fee: 'Kilometrit',
    priceitem_waiting_fee: 'Odotusaika (tuntia)',
    priceitem_terminal_pickup_fee: 'Terminaalinouto',
    price_estimate: '(arvio)',
    price_minimum_price: 'Vähimmäisveloitus',
    price_estimate_price: 'Arvioitu hinta',
    price_no_estimate: 'Hinta-arviota ei saatu luotua',
    price_hourly_min_hours: '3h (minimi)',
}

const users = {
    users_role: 'Rooli',
    users_role_driver: 'Kuljettaja',
    users_role_organizer: 'Ajojärjestelijä',
    users_role_admin: 'Palvelun pääkäyttäjä',
    users_role_customer: 'Peruskäyttäjä',
    users_role_customeradmin: 'Yrityksen pääkäyttäjä',
    users_load_failed: 'Virhe käyttäjiä ladatessa',
    users_invite_new_user: 'Kutsu uusi käyttäjä yritykseen',
    users_invitation_email: 'Kutsuttavan henkilön sähköpostiosoite',
    users_send_invite: 'Lähetä kutsu',
    users_users: 'Käyttäjät',
    users_management_title: 'Yritys & Käyttäjät hallinta',
    users_role_changed_success: 'Rooli vaihdettu onnistuneesti',
    users_role_changed_error: 'Roolin vaihto epäonnistui',
    users_user_disabled_success: 'Käyttäjä poistettu käytöstä onnistuneesti',
    users_user_disabled_error: 'Käyttäjän poistaminen käytöstä epäonnistui',
    users_invoicing_info: 'Laskutustiedot',
    users_billing_email: 'Laskutuksen sähköpostiosoite',
    users_billing_street_address: 'Yrityksen postiosoite',
    users_billing_company_identifier: 'Y-tunnus (muodossa 1234567-8)',
}

const datepicker = {
    datepicker_error_minDateMessage: 'Päivämäärä ei voi olla menneisyydessä',
}

const serverErrors = {
    serverError_common_server_error: 'Palvelimelle ei saatu yhteyttä',
    serverError_too_much_data_to_load:
        'Liian paljon ladattavaa tietoa. Vain ladatut tiedot näytetään.',
}

const passengerInfoMessages = {
    info_pickup_time: 'Noutoaika',
    info_pickup_address: 'Nouto-osoite',
    info_pickup_info: 'Lisätiedot',
    info_driver: 'Kuljettaja',
    info_licenseplate: 'Rekisterinumero',
    info_vehicle: 'Auto',
    info_infophone: 'Päivystysnumero',
    info_not_available: 'Kuljetuksen tiedot eivät ole saatavilla',
    info_car_is_on_its_way: 'Autonne on matkalla',
    info_ride_information: 'Kuljetuksen tiedot',
}

const weekDayMessages = {
    monday: 'Maanantai',
    tuesday: 'Tiistai',
    wednesday: 'Keskiviikko',
    thursday: 'Torstai',
    friday: 'Perjantai',
    saturday: 'Lauantai',
    sunday: 'Sunnuntai',
}

const cancelModal = {
    cancel_modal_cancel_order: 'Peru tilaus',
    cancel_modal_cancel_order_overdue_part1:
        'Tilauksen peruuttamisesta aiheutuu kuluja, koska tilauksen alkuun on alle',
    cancel_modal_cancel_order_overdue_part2: 'tuntia.',
    cancel_modal_cancel_order_not_overdue_part1:
        'Tilauksen peruutus on maksutonta, koska tilauksen alkuun on yli',
    cancel_modal_cancel_order_button: 'Peru tilaus',
}

const confirmOrderModal = {
    confirm_modal_confirm_order: 'Vahvista tilaus',
    confirm_modal_cancel_text_part1: 'Voit maksutta perua tilauksen',
    confirm_modal_cancel_text_part2:
        'tuntia ennen sen alkua ja maksutta tehdä muutoksia tilaukseen',
    confirm_modal_cancel_text_part3: 'tuntia ennen sen alkua.',
    confirm_modal_handle_orders_fast:
        'Pyrimme käsittelemään kaikki tilaukset mahdollisimman nopeasti.',
    confirm_modal_email_checbox_label:
        'Kun tilaus on vahvistettu, lähetä minulle sähköposti jossa on tilauksen tiedot sekä kalenterivaraus.',
    confirm_modal_order_button: 'Lähetä tilaus',
    confirm_accept_policy_on_send_partial: 'Lähettämällä hyväksyt',
    confirm_usage_policy_link_title: 'käyttöehdot',
}

const status = {
    status_not_completed: 'Ei vahvistettu',
    status_confirmed: 'Vahvistettu',
    status_not_handled: 'Ei käsitelty',
    status_handled: 'Käsitelty',
    status_not_signed: 'Ei kuitattu',
    status_signed: 'Kuitattu',
}

const cards = {
    cards_get_cards_error: 'Korttitietojen haku epäonnistui',
    cards_add_new_card: 'Lisää uusi kortti',
    cards_cards: 'Kortit',
    cards_delete_card_error: 'Kortin poisto epäonnistui',
    cards_delete_card_success: 'Kortti poistettu',
    cards_confirm_card_delete: 'Kyllä, haluan poistaa kortin',
    cards_delete_card_modal_header_text: 'Kortin poisto',
    cards_delete_card_modal_typo1: 'Oletko varma että haluat poistaa kortin? Jos kortilla on avoimia tilauksia, niiden maksutavaksi vaihdetaan "lasku".',
    cards_payment_method: 'Maksutapa',
    cards_credit_card: 'Luottokortti',
    cards_invoice: 'Lasku',
    cards_choose_credit_card: 'Valitse maksukortti',
    cards_rename_title: 'Muuta kortin nimeä',
    cards_name: 'Nimi',
    cards_maskedPan: 'Kortin numero',
    cards_edit_card_name: 'Muokkaa kortin nimeä',
    cards_add_new_card_error: 'Kortin lisäys epäonnistui',
    cards_new_card_added: 'Kortti lisätty onnistuneesti',
    cards_other_user_card: '(Tilaajan valitsema kortti)',
    cards_charge: 'Suorita veloitus kortilta',
    cards_charge_in_progress: 'Veloitus käynnissä...',
    cards_charge_complete: 'Veloitus onnistui',
    cards_charge_failed: 'Veloitus epäonnistui',
}

const backendErrorMsgs = {
    invalid_passenger_count: 'Tarkista matkustajien määrä',
}

export default {
    ...roleMessages,
    ...generalMessages,
    ...sidebarMessages,
    ...loginMessages,
    ...organizationMessages,
    ...registrationMessages,
    ...tableMessages,
    ...vehicleMessages,
    ...orders,
    ...partners,
    ...organizer,
    ...pricing,
    ...rides,
    ...schedules,
    ...datepicker,
    ...serverErrors,
    ...users,
    ...profileMessages,
    ...passengerInfoMessages,
    ...weekDayMessages,
    ...cancelModal,
    ...confirmOrderModal,
    ...status,
    ...cards,
    ...backendErrorMsgs,
}
